<template>
    <div inset class="card-meuspedidos-container">
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header class="card-meuspedidos-text card-pedidos-titles">
                    <div class="card-meuspedidos-container01">
                        <div>
                            <h1 style="margin: 0px; padding: 0px"><span>{{ typeof pedido.produtos[0].dscapre == 'object' ? pedido.produtos[0].dscapre[0] : pedido.produtos[0].dscapre }}</span></h1>
                            <div style="display: flex; align-items: center;">
                                <v-icon style="margin-right: 3px; color: var(--dl-color-principal)">mdi-clock-check-outline</v-icon>
                                <small v-if="$axios.getLocal('lang') && $axios.getLocal('lang').data != 'en-US'" style="margin-left: 3px;">{{ $t_('visitacao') }}{{ $moment(pedido.produtos[0].dt_apre).format('DD/MM/YYYY HH:mm') }}</small>
                                <small v-else style="margin-left: 3px;"><strong>{{ $t_('visitacao') }}: </strong>{{ $moment(pedido.produtos[0].dt_apre).format('MM/DD/YYYY HH:mm') }}</small>
                            </div>
                            <div style="display: flex;  align-items: center;">
                                <v-icon style="margin-right: 5px; color: var(--dl-color-principal)">mdi-ticket-confirmation-outline</v-icon>
                                <small>{{ $t_('LOCALIZADOR') }}:</small>
                                <small style="margin-left: 3px;">{{pedido.localizador}}</small>
                            </div>
                        </div>
                        <div class="card-meuspedidos-container07"  ><!--Retorna "false" e não apresenta-->
                        <v-row cols="12" class="d-flex justify-center">
                            <v-col class="d-flex justify-center" cols="12" v-if="pedido.boleto_url">
                                <v-btn dark 
                                    block
                                    class="uk-botoes"
                                    @click="openLink(pedido.boleto_url)">
                                    <v-icon>mdi-chevron-down</v-icon>
                                    <span>{{$t_('BOLETO')}}</span>
                                </v-btn>
                            </v-col>
                            <v-col class="d-flex justify-center" cols="12" v-if="pedido.boleto_url">
                                <v-btn dark
                                    block
                                    :color="'red'"
                                    class="uk-botoes "
                                    @click="modalBoleto = true">
                                        <v-icon>mdi-chevron-down</v-icon>
                                        <span>{{$t_('CANCELAR_BOLETO')}}</span>
                                </v-btn>
                            </v-col>
                            <template>
                                <div class="text-center">
                                    <v-dialog
                                    v-model="dialog"
                                    width="500"
                                    >
                                    <v-card>
                                        <v-card-title :style="'color: ' + $config.dados.corprimaria" class=" d-flex justify-center align-center text-h5 grey lighten-3">
                                        {{$t_('combo')}}
                                        </v-card-title>
                                        <v-card-text >

                                                <div class="card-meuspedidos-container-combo" v-for="(produto, key) in pedidosCombo" :key="key">
                                                       
                                                        <div class="card-meuspedidos-container06"
                                                            :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado' : ''">
                                                            <h1 class="card-meuspedidos-text08"
                                                                :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado-color' : ''">
                                                                {{ produto.dscproduto}}
                                                            </h1>
                                                            {{produto.dsctipoproduto}}
                                                            <p class="card-meuspedidos-text08"
                                                                :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado-color' : ''">
                                                                <strong :style="'color: ' + $config.dados.corprimaria"><br><div class="segDia" :style="'background-color: ' + $config.dados.corsecundaria">{{$t_('reserva_para')}} {{ $util.formatDateHour(produto.dthr_apresentacao) }}<template v-if="produto.extra && produto.extra.segundo_dia" > e {{produto.extra.segundo_dia | data}}</template></div></strong>
                                                            </p>
                                                        </div>

                                                        <div class="card-meuspedidos-container-portador">
                                                            <button  v-if="produto.dthr_apresentacao && $moment(produto.dthr_apresentacao).format('YYYYMMDD') >= $moment().format('YYYYMMDD') && produto.status == 'PA' || (produto.limitCombo && produto.libera_impressao )"
                                                                class="cursor-pointer pl-1 pr-1 flex button-wallet my-4"
                                                                style="border-radius: 3px; margin: 0 auto; color: white"
                                                                :style="'background-color:' + $config.dados.corprimaria"
                                                                @click="imprimirIngressoCombo(produto)"
                                                            >
                                                                <v-icon color="white">mdi-printer</v-icon>
                                                            </button>
                                                            <button v-if="$config.mobile() && produto.status == 'PA' "
                                                                class="cursor-pointer pl-1 pr-1 flex button-wallet"
                                                                style="background-color: #000; color: #fff; border-radius: 3px; margin: 0 auto;"
                                                                @click="getWallet(pedido.idmovimentacao)"
                                                            >
                                                                <img 
                                                                    class="py-1 mr-1"
                                                                    src="@/assets/images/wallet.png" 
                                                                    alt 
                                                                    width="15" 
                                                                />
                                                                {{$t_('nome_wallet')}}
                                                            </button>

                                                            <template v-if="produto.portador && produto.portador[0].nome">
                                                                <strong class="mb-1">{{$t_('portador_cadastrado')}}</strong>
                                                                <span>{{ produto.portador[0].nome }} - {{ tratarDocumento(produto.portador[0].documento) }}</span>
                                                            </template>
                                                        </div>
                                                        <div class="card-meuspedidos-container08"
                                                            :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado' : ''">
                                                            <span class="card-meuspedidos-text06 mr-3 card-pedidos-titles"><span>{{$t_('total')}}</span></span>
                                                            <div class="card-meuspedidos-container09">
                                                                <span class="card-meuspedidos-text11"
                                                                    :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado-color' : ''">
                                                                    {{$util.asMoney(produto.total)}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="card-meuspedidos-container10"
                                                            @click="cancelarItem(produto, produto.idmovimentacaoitem)" v-if="!produto.pode_cancelar">
                                                            <div class="card-meuspedidos-container11">
                                                                <span class="card-meuspedidos-text11">
                                                                    <v-icon :color="$config.dados.corprimaria">mdi-delete mdi-color-gray</v-icon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <hr style="border: solid 1px gray; width: 100%"/>
                                                    </div>
                                        </v-card-text>

                                        <v-divider></v-divider>

                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="dialog = false"
                                        >
                                            {{$t_('sair')}}
                                        </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                                </div>
                            </template>
                            <v-col class=" d-flex justify-center" cols="12" v-if="!pedido.boleto_url">
                                <!--SELECIONA TODOS OS INGRESSOS PARA IMPRESSÃO-->
                                <v-btn v-if="pedido.produtos.find(item => this.$moment(item.dt_apre).format('YYYYMMDD') >= this.$moment().format('YYYYMMDD')) && pedido.status == 'PA' || (limitAll && comboLimit)" dark
                                    @click="imprimirTodos(pedido)" :color="$config.dados.corprimaria" class="uk-botoes">
                                    <v-icon>mdi-chevron-down</v-icon>
                                    <span>{{$t_('salvar_ingresso')}}</span>
                                </v-btn> 
                            </v-col>
                        </v-row>
                        </div>
                        <div class="card-meuspedidos-container03">
                            <span class="card-meuspedidos-text06 mr-3 card-pedidos-titles"><span>{{$t_('total')}}</span></span>
                            <div class="card-meuspedidos-container04">
                                <span
                                    class="card-meuspedidos-text06 card-pedidos-titles">{{$util.asMoney(pedido.valorTotal)}}</span>
                                <!--span-- class="card-meuspedidos-text07">,00</!--span-->
                            </div>
                        </div>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <template><!--TIRAR-->
                        <div class="card-meuspedidos-container05" v-for="(produto, key) in pedido.produtos" :key="key">
                        <!-- <div class="card-meuspedidos-container05" v-for="(produto, key) in tratarPaginacao(pedido.produtos, page, perPage)" :key="key"> -->
                            <div class="card-meuspedidos-container06"
                                :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado' : ''">
                                <h1 class="card-meuspedidos-text08"
                                    :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado-color' : ''">
                                    {{ produto.tpproduto || produto.produto }}
                                </h1>
                                {{produto.produto}}
                                <p class="card-meuspedidos-text08"
                                    :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado-color' : ''">
                                    <strong :style="'color: ' + $config.dados.corprimaria">
                                        <div class="segDiaCombo" :style="'background-color: ' + $config.dados.corsecundaria">
                                            {{$t_('reserva_para')}} {{ $util.formatDateHour(produto.dt_apre) }}
                                            <template v-if="produto.extra && produto.extra.segundo_dia" > 
                                                e {{produto.extra.segundo_dia | data}}
                                            </template>
                                        </div>
                                    </strong>
                                </p>
                            </div>

                            <div class="card-meuspedidos-container-portador">
                           
                                <button v-if="produto.dt_apre && $moment(produto.dt_apre).format('YYYYMMDD') >= $moment().format('YYYYMMDD') && produto.status == 'PA'|| produto.limitCombo"
                                    class="cursor-pointer pl-1 pr-1 flex button-wallet my-4"
                                    style="border-radius: 3px; margin: 0 auto; color: white"
                                    :style="'background-color:' + $config.dados.corprimaria"
                                    @click="imprimirIngresso(pedido.idmovimentacao, produto)"
                                >
                                    <v-icon color="white">mdi-printer</v-icon>
                                    {{$t_('salvar')}}
                                </button>
                                <button v-if="$config.mobile() && produto.status == 'PA' "
                                    class="cursor-pointer pl-1 pr-1 flex button-wallet"
                                    style="background-color: #000; color: #fff; border-radius: 3px; margin: 0 auto;"
                                    @click="getWallet(pedido.idmovimentacao)"
                                >
                                    <img 
                                        class="py-1 mr-1"
                                        src="@/assets/images/wallet.png" 
                                        alt 
                                        width="15" 
                                    />
                                    {{$t_('nome_wallet')}}
                                </button>
                                <!-- End wallet button -->

                                <template v-if="produto.portador && produto.portador[0].nome">
                                    <strong class="mb-1">{{$t_('portador_cadastrado')}}</strong>
                                    <span>{{ produto.portador[0].nome }} - {{ tratarDocumento(produto.portador[0].documento) }}</span>
                                </template>
                            </div>

                            <div class="card-meuspedidos-container08"
                                :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado' : ''">
                                <div class="card-meuspedidos-container09">
                                    <span class="card-meuspedidos-text11"
                                        :class="produto.status == 'PC' || produto.status == 'CA' ? 'cancelado-color' : ''">
                                        {{$util.asMoney(produto.total)}}
                                    </span>
                                </div>
                            </div>
                            <div class="card-meuspedidos-container10"
                                @click="cancelarItem(pedido.idmovimentacao, produto.iditem, produto)" v-if="produto.cancelar">
                                <div class="card-meuspedidos-container11">
                                    <span class="card-meuspedidos-text11">
                                        <v-icon :color="$config.dados.corprimaria">mdi-delete mdi-color-gray</v-icon>
                                    </span>
                                </div>
                            </div>
                            <hr style="border: solid 1px gray; width: 100%"/>
                        </div>

                        <div class="card-meuspedidos-container05" v-if="pedido.garantia_valor" style="width: 100%;" :class="pedido.garantia_valor + pedido.garantia_valor_juros - pedido.garantia_valor_cancelado == 0 ? 'cancelado' : ''">
                            <div class="card-meuspedidos-container06" style="width: 100%; align-items: end;" >
                                <span class="mr-3 card-pedidos-titles " style="font-weight: bold;" :class="pedido.garantia_valor + pedido.garantia_valor_juros - pedido.garantia_valor_cancelado == 0 ? 'cancelado-color' : ''">
                                    {{ $t_('garantia_estendida') }} : {{$util.asMoney(pedido.garantia_valor + pedido.garantia_valor_juros - pedido.garantia_valor_cancelado)}}
                                </span>
                            </div> 
                        </div>
                        <div class="card-meuspedidos-container05" v-if="pedido.garantia_valor" style="width: 100%;" :class="pedido.garantia_valor + pedido.garantia_valor_juros - pedido.garantia_valor_cancelado == 0 ? 'cancelado' : ''">
                            <div class="card-meuspedidos-container06" style="width: 100%; align-items: end;" >
                                <span class="mr-3 card-pedidos-titles " style="font-weight: bold;word-break: break-word;" :class="pedido.garantia_valor + pedido.garantia_valor_juros - pedido.garantia_valor_cancelado == 0 ? 'cancelado-color' : ''">
                                    {{ $t_('GARANTIA_REFERENCIA') }} : {{pedido.idmovimentacao}}
                                </span>
                                <button :class="['copy',copied ? 'copied':'']" @click="copy(pedido.idmovimentacao)">{{ $t_(copied ?'COPIADO' :'COPIAR')}}</button>
                            </div> 
                        </div>

                    </template><!--TIRAR-->
                    <ModalConfirmarCancelamento :showModalCancelamento="showModalCancelamento" :idmovimentacao="idmov_cancel"
                        :idmovimentacaoitem="iditem_cancel" :produto="prod_cancel"
                        @update-dialog-cancel="updateShowModalCancelamento" />

                    <v-dialog v-model="modalBoleto" width="500" persistent>
                        <v-card >
                            <v-card-title>{{ $t_('cancelamento') }}</v-card-title>
                            <v-card-text class="mt-5">{{ $t_('MSN_BOLETO1') }} <br> </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="d-flex justify-center align-center flex-row pa-3" >
                                <v-btn :color="$config.dados.corsecundaria" text @click="modalBoleto2 = true" v-once>
                                   {{ $t_('SIM') }}
                                </v-btn>
                                <v-btn :color="$config.dados.corsecundaria" text @click="modalBoleto = !modalBoleto" v-once>
                                   {{ $t_('NAO') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="modalBoleto2" width="500" persistent >
                        <v-card >
                            <v-card-title>{{ $t_('cancelamento') }}</v-card-title>
                            <v-card-text class="mt-5">{{ $t_('MSN_BOLETO2') }} <br> </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="d-flex justify-center align-center flex-row pa-3">
                                <v-btn :color="$config.dados.corsecundaria" text @click="mensagem" v-once>
                                    {{ $t_('SIM') }}
                                </v-btn>
                                <v-btn :color="$config.dados.corsecundaria" text @click="modalBoleto3 = true" v-once>
                                   {{ $t_('NAO') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="modalBoleto3" width="500" persistent >
                        <v-card >
                            <v-icon @click="modalBoleto3 = !modalBoleto3" class="fechar">mdi-close mdi-color-black</v-icon>
                            <v-card-title>{{ $t_('cancelamento') }}</v-card-title>
                            <v-card-text class="mt-5">{{ $t_('MSN_BOLETO') }} <br> </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions class="d-flex justify-center align-center flex-row pa-3">
                                <v-btn :color="$config.dados.corsecundaria" text @click="cancelarBoleto(pedido.idmovimentacao)" v-once>
                                    {{ $t_('confirmarCancelamento') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-expansion-panel-content>
            </v-expansion-panel>    
        </v-expansion-panels>
    </div>
</template>

<script>
import { regrasDoNegocio } from "../../package.json";
import {
BASE_URL
} from '../plugins/service/Endpoints.js';
import Util from "../controllers/Util.controller.js";
    export default {
        name: 'CardMeuspedidos',
        components: {
            ModalConfirmarCancelamento: () => import('@/components/modal-confirmar-cancelamento.vue'),
        },
        props: {
            pedido: {
                type: Object,
                default: {},
            },
        },

        data() {
            return {
                BASE_URL: BASE_URL,
                showModalCancelamento: false,
                idmov_cancel: null,
                iditem_cancel: null,
                prod_cancel: {},
                trilhaShow: false,
                trilhaShow2: false,
                trilhaDados: false,
                trilhaData: false,
                modalBoleto: false,
                modalBoleto2: false,
                modalBoleto3: false,
                cancelaBoleto: false,
                dialog: false,
                pedidosCombo: '',
                comboLimit:false,
                limitAll:false,
                copied: false
            }
        },

        methods: {
            copy(idmovimentacao){
                const util = new Util()
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(idmovimentacao)
                    this.copied = true
                    util.snackbar(this.$t_("COPY_SUCCESS"),"success")
                }else{
                     util.snackbar(this.$t_("COPY_ERROR"),"error")
                }
            },
            mensagem(){
                this.$config.alertWarn(this.$t_('PROIBIDO_CANCELAR'));
                this.$axios.rota('Home');
            },
            cancelarBoleto(idMovimentacao) {
                this.$superingresso.cancelarBoleto(idMovimentacao)
                .then(() => {
                    this.modalBoleto = false
                    this.modalBoleto2 = false
                    this.modalBoleto3 = false
                    this.$axios.rota('Checkout')
                })
            },
            openLink(link) {
                let abrir = window.open(link, 'Impressão', '_system', 'location=yes');
                // Caso tenho bloqueador de POPUP (SAFARI)
                if (abrir == null || typeof(open) == 'undefined'){
                    location.href = link
                }
            },

//================= IMPRIME SOMENTE UM INGRESSO DO COMBO ==============================
            imprimirIngressoCombo(produto) {
                if(produto.tokenIngresso){
                    this.openLink(`${this.BASE_URL}SuperIngresso/impressao/ingresso/${produto.idmovimentacaoitem}/${produto.tokenIngresso}${window.location.search}`)
                }
            },
//================= IMPRIME SOMENTE UM INGRESSO ==============================

            imprimirIngresso(idmovimentacao, produto) {
            
                this.$superingresso.getImpressao(idmovimentacao)
                .then(response => {
                    var itens =response[0].itens;
                   
                    if(produto.combo){
                    
                        this.dialog = true;
                        itens.forEach((item)=>item.limitCombo = produto.limitCombo)
                        this.pedidosCombo= itens;
                        
                    }else{
                        let item = itens.find( it => it.idmovimentacaoitem == produto.iditem)
                        if(item.tokenIngresso){
                            this.openLink(`${this.BASE_URL}SuperIngresso/impressao/ingresso/${item.idmovimentacaoitem}/${item.tokenIngresso}${window.location.search}`)
                        }
                    }
                })
            },
// ============================ IMPRIME TODOS OS INGRESSO ====================
            imprimirTodos(pedido) {
                this.$superingresso.getImpressao(pedido.idmovimentacao)
                .then(response => {
                    var itens = response[0].itens;

                    let impressao = itens.find((item) => {
                        if(pedido.produtos[0].combo) return item.idmovimentacaoitem != pedido.produtos[0].iditem;
                        return item.idmovimentacaoitem == pedido.produtos[0].iditem;
                    });
                    if(typeof impressao != "undefined"){
                        return this.openLink(this.BASE_URL + 'SuperIngresso/impressao/ingresso/' + impressao.tokenIngresso + window.location.search);
                    }
                });
            },

            cancelarItem(idmov, iditem, produto) {
                this.idmov_cancel          = idmov
                this.iditem_cancel         = iditem
                this.prod_cancel           = produto
                this.showModalCancelamento = true
            },
            updateShowModalCancelamento(show) {
                this.showModalCancelamento = show;
            },

            tratarDocumento(doc) {
                return doc.substring(0, 3) + ".***.***-" +  doc.substring(9, 12)
            },

            getWallet(idmovimentacao) {               

                // verifica a possibilidade de impressão do wallet
                this.$superingresso.getImpressao(idmovimentacao)
                    .then(response => {
                        let result = response[0].itens[0];

                        // se impressão liberada retorna a url para download do pkpass
                        if(result.libera_wallet) {
                            this.$superingresso.getDownloadURLPkPassWallet(result.tokenIngresso, result.idmovimentacaoitem)
                                .then(response => {

                                    // se retorna resposta realiza o download
                                    if(response) {                                     

                                        let abrir = window.open(this.BASE_URL + response, 'Impressão', '_system', 'location=yes');
                                        
                                        // Caso tenho bloqueador de POPUP (SAFARI)
                                        if (abrir == null || typeof(open) == 'undefined'){
                                            location.href = this.BASE_URL + response;
                                        }
                                        
                                    }
                                })
                                .catch(err => (console.log(err)));
                        }                              
                    })
            }
        },
        beforeMount() {        
        const allCombo = this.pedido.produtos.every((item)=>item.combo)
        this.limitAll = allCombo
        this.pedido.produtos.forEach((item)=>{
            if(item.combo){
                const diff = new Date(this.pedido.dt_apre).getTime()
                const expireTime = 1000*60*60*regrasDoNegocio.comboLimite
                const now = new Date().getTime()
                const res = diff + expireTime
                const comboLimit = res >now
                item.limitCombo = comboLimit
            }
        })
        this.comboLimit =this.pedido.produtos.every((item)=>item.limitCombo)
        }
    }
</script>


<style scoped>
.copy{
    padding: 6px 12px;
    color: white;
    background: rgb(17, 109, 255);;
    border-radius: 12px;
        margin-top: 8px;
}
.copied{
    background-color: #529e52;
}
    .button-wallet{
        width: 110px!important;
        height: 25px!important;
        display: flex!important;
        justify-content: center!important;
        align-items: center!important;
    }
    .modalbuttons {
        display: flex;
    }
    .sucessotrilha {
        border-radius: 5px; 
        width: 100%; 
    }

    h1 {
        border: none !important;
        font-size: 18px !important;   
        margin: 5px;
    }

    hr { display: none }

    .cancelado {
        text-decoration: line-through;
    }

    .cancelado-color {
        color: var(--dl-color-gray-700) !important;
    }

    .card-meuspedidos-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .card-meuspedidos-container01 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        padding: 10px;
        align-self: center;
        align-items: center;
        border-color: var(--dl-color-gray-900);
        border-width: 1px;
        border-radius: var(--dl-radius-radius-radius8);
        margin-bottom: 0px;
        flex-direction: row;
        justify-content: flex-start;

    }

    .card-meuspedidos-container02 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .card-meuspedidos-text {
        color: var(--dl-color-principal);
        font-size: 17px;
        border: none !important;
        margin-bottom: 0px;

    }

    .card-meuspedidos-text02 {
        color: var(--dl-color-gray-500);
        font-size: 12px;
    }

    .card-meuspedidos-container03 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        align-items: flex-end;
        flex-direction: row;
        justify-content: flex-end;
    }

    .card-meuspedidos-text03 {
        color: var(--dl-color-principal);
        font-size: px;
        margin-right: 10px;
    }

    .card-meuspedidos-container04 {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    .card-meuspedidos-text05 {
        color: var(--dl-color-principal);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        margin-right: 5px;
    }

    .card-meuspedidos-text06 {
        color: var(--dl-color-principal);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
    }

    .card-meuspedidos-text07 {
        color: var(--dl-color-principal);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
    }

    .card-meuspedidos-container05 {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        padding: 10px;
        align-self: center;
        transition: 0.3s;
        align-items: center;
        border-color: var(--dl-color-gray-700);
        border-width: 1px;
        margin-bottom: 0px;
        flex-direction: row;
        justify-content: flex-start;
        background-color: #f5f5f5;
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        position: relative;
    }
    .card-meuspedidos-container-combo {
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        padding: 10px;
        align-self: center;
        transition: 0.3s;
        align-items: center;
        border-color: var(--dl-color-gray-700);
        border-width: 1px;
        margin-bottom: 0px;
        flex-direction: row;
        justify-content: space-between;
        background-color: #f5f5f5;
        border-top-width: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        position: relative;
    }

    .card-meuspedidos-container05:hover {
        padding-top: 10px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 10px;
        background-color: #ffffff;
    }
    .card-meuspedidos-container-combo:hover {
        padding-top: 10px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 10px;
        background-color: #ffffff;
    }

    .card-meuspedidos-container06 {
        flex: 0 0 auto;
        width: 45%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .card-meuspedidos-text08 {
        color: var(--dl-color-gray-500);
        font-size: 12px;
        margin: 0px;
    }
    .segDia{
        width: 100%;
        padding: 6px;
        border-radius: 5px;
        margin: 10px 0px 10px 0px;
    }
    .segDiaCombo{
        width: 100%;
        padding: 3px;
        font-size: 12px;
        border-radius: 5px;
        margin: 10px 0px 10px 0px;
    }

    .card-meuspedidos-container07 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }

    .card-meuspedidos-container-portador {
        flex: 0 0 auto;
        width: 27%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding: 30px
    }

    .card-meuspedidos-button {
        color: var(--dl-color-gray-white);
        display: flex;
        font-size: 12px;
        align-self: center;
        margin-top: 0px;
        text-align: center;
        align-items: center;
        border-width: 0px;
        flex-direction: row;
        justify-content: center;
        /* background-color: var(--dl-color-gray-black); */
    }

    .card-meuspedidos-icon {
        fill: var(--dl-color-gray-white);
        width: 18px;
        height: 18px;
    }

    .card-meuspedidos-container08 {
        flex: 0 0 auto;
        width: 18%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
    }

    .card-meuspedidos-container09 {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    .card-meuspedidos-text10 {
        color: var(--dl-color-gray-black);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        margin-right: 5px;
    }

    .card-meuspedidos-text11 {
        color: var(--dl-color-gray-black);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
    }

    .card-meuspedidos-text12 {
        color: var(--dl-color-gray-black);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
    }

    .card-meuspedidos-container10 {
        flex: 0 0 auto;
        width: 5%;
        display: flex;
        padding: 10px;
        align-self: center;
        transition: 0.3s;
        align-items: center;
        margin-bottom: 0px;
        flex-direction: row;
        justify-content: flex-start;
        background-color: #f5f5f5;
        cursor: pointer;
    }

    .card-meuspedidos-container10:hover {
        padding-top: 10px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 10px;
        background-color: #eaeaea;
    }

    .card-meuspedidos-container11 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }

    .card-meuspedidos-text13 {
        color: var(--dl-color-gray-500);
        font-size: 12px;
    }

    .card-meuspedidos-container12 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
    }

    .card-meuspedidos-button1 {
        color: var(--dl-color-gray-white);
        display: flex;
        font-size: 12px;
        align-self: center;
        margin-top: 0px;
        text-align: center;
        align-items: center;
        border-width: 0px;
        flex-direction: row;
        justify-content: center;
        background-color: var(--dl-color-gray-black);
    }

    .card-meuspedidos-icon2 {
        fill: var(--dl-color-gray-white);
        width: 18px;
        height: 18px;
    }

    .card-meuspedidos-container13 {
        flex: 0 0 auto;
        width: 33.33%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
    }

    .card-meuspedidos-container14 {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
    }

    .card-meuspedidos-text15 {
        color: var(--dl-color-gray-black);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        margin-right: 5px;
    }

    .card-meuspedidos-text16 {
        color: var(--dl-color-gray-black);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
    }

    .card-meuspedidos-text17 {
        color: var(--dl-color-gray-black);
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
    }

    @media(max-width: 991px) {
        .card-meuspedidos-container07 {
            display: block;
            align-items: center;
        }
        .card-meuspedidos-container07 > button {
            margin: 5px auto !important;
            width: 100%;
        }
        .sucessotrilha {
            width: 100%; 
        }
        .modalbuttons {
            display: block;
        }
        .modalbuttons > * {
            margin-bottom: 10px;
            width: 100%;
        }
        .uk-botoes {
            width: 100%;
        }
    }

    @media(max-width: 479px) {
        .segDia{
            width: 100%;
        }
        .card-meuspedidos-text08 {
            width: calc(100% - 47px)
        }
        .card-meuspedidos-container-portador {
            flex: 0 0 auto;
            width: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
        }

        .card-meuspedidos-container01 {
            width: 100%;
            align-items: flex-start;
            flex-direction: column;
            
        }

        .card-meuspedidos-container02 {
            width: 100%;
            align-self: center;
            align-items: center;
        }

        .card-meuspedidos-container03 {
            width: 100%;
            justify-content: center;
        }

        .card-meuspedidos-text03 {
            align-self: flex-start;
            text-align: left;
        }

        .card-meuspedidos-text05 {
            align-self: flex-start;
        }

        .card-meuspedidos-text06 {
            align-self: flex-start;
        }

        .card-meuspedidos-text07 {
            align-self: flex-start;
        }

        .card-meuspedidos-container05 {
            width: 100%;
            flex-wrap: wrap;
            align-items: flex-start;
            flex-direction: column;
        }
        .card-meuspedidos-container-combo {
            width: 100%;
            flex-wrap: wrap;
            align-items: flex-start;
            flex-direction: column;
        }

        .card-meuspedidos-container06 {
            width: 100%;
            align-items: center;
            margin: 0px auto;
        }

        .card-meuspedidos-container07 {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .card-meuspedidos-button {
            align-self: center;
            justify-content: center;
        }

        .card-meuspedidos-container08 {
            width: 100%;
            margin-top: 10px;
            align-items: center;
            margin-bottom: 10px;
        }

        .card-meuspedidos-text10 {
            align-self: flex-start;
        }

        .card-meuspedidos-text11 {
            align-self: flex-start;
            font-size: 22px;
        }

        .card-meuspedidos-text12 {
            align-self: flex-start;
        }

        .card-meuspedidos-container10 {
            width: 47px;
            flex-wrap: wrap;
            align-items: flex-start;
            flex-direction: column;
            position: absolute;
            right: 0px;
        }

        .card-meuspedidos-container11 {
            width: 100%;
            align-items: center;
        }

        .card-meuspedidos-container12 {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .card-meuspedidos-button1 {
            align-self: center;
            justify-content: flex-start;
        }

        .card-meuspedidos-container13 {
            width: 100%;
            margin-top: 10px;
            align-items: center;
            margin-bottom: 10px;
        }

        .card-meuspedidos-text15 {
            align-self: flex-start;
        }

        .card-meuspedidos-text16 {
            align-self: flex-start;
        }

        .card-meuspedidos-text17 {
            align-self: flex-start;
        }
    }

    @media(max-width: 464px) {
        hr { display: block }
    }
</style>