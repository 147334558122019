<template>
  <v-dialog v-model="localShowModal" width="600">
    <v-card>
      <v-icon @click="changeShow()" class="fechar">mdi-close mdi-color-black</v-icon>
      <v-card-title class="mt-5">{{ $t_("PROD_CHECKOUT_TITLE") }}</v-card-title>
      <v-divider></v-divider>
      <div
        class="modal-produtos-container07"
        v-for="(item, index) in prods.produtos"
        :key="index"
      >
        <div class="modal-produtos-container09">
          <div class="modal-produtos-container10">
            <span class="modal-produtos-text01">{{ item.dscproduto }}</span>
            <div
              class="modal-produtos-container11"
              v-if="item.valor_sem_desconto > 0 && $config.dados.mostrarDescontoCombo"
            >
              <span class="modal-produtos-text03-1" :style="'color: #AAAAAA'"
                >{{ $t_("PRODUTO_DE") }}{{ item.valor_sem_desconto | moeda }}</span
              >
              <span class="modal-produtos-text04-1" :style="'color: #AAAAAA'"
                >,{{ item.valor_sem_desconto | centavos }}</span
              >
            </div>
            <div
              class="modal-produtos-container11"
              v-if="item.valor_sem_desconto > 0 && $config.dados.mostrarDescontoCombo"
            >
              <span
                class="modal-produtos-text03"
                :style="'color:' + $config.dados.corprimaria"
                >{{ $t_("PRODUTO_POR") }}{{ item.preco | moeda }}</span
              >
              <span
                class="modal-produtos-text04"
                :style="'color:' + $config.dados.corprimaria"
                >,{{ item.preco | centavos }}</span
              >
            </div>
            <div class="modal-produtos-container11" v-else>
              <span
                class="modal-produtos-text03"
                :style="'color:' + $config.dados.corprimaria"
                >{{ item.preco | moeda }}</span
              >
              <span
                class="modal-produtos-text04"
                :style="'color:' + $config.dados.corprimaria"
                >,{{ item.preco | centavos }}</span
              >
            </div>
          </div>
          <div class="modal-produtos-container12">
            <v-select
              hide-selected
              v-model="item.qtd"
              :items="qtdSelect(item.qtd)"
              class="rounded-pill bg-corquarternaria pa-0 app-qtd"
              hide-details
              dense
              outlined
              style="width: 100px; height: 35px"
              @click="(manual = item), (manual_tipo_produtos = prods), (chave = index)"
              @input="
                $carrinho.AddPedido.info_compra &&
                $carrinho.AddPedido.info_compra.pessoa_cartoes &&
                item.qtd > 0 &&
                item.cartao_dsc
                  ? selectCartao(item, {})
                  : selecao(item, {
                      idapresentacao: dataHorario
                        ? dataHorario.idapresentacao
                        : $carrinho.AddPedido.idapresentacao,
                      dt_apre: dataApre,
                      dt_hora: dataHorario,
                      dscapre: prods.dsctipoproduto,
                    })
              "
            >
            </v-select>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import MIXES from "@/mixeds/produtoData.mixeds.js";

export default {
  name: "ModalCheckoutProd",
  created() {},
  mixins: [MIXES],

  data() {
    return {
      manual: [],
      manual_show: false,
      manual_tipo_produtos: [],
      chave: 0,
      qtd: [],
      idtipo_pessoa: null,
      qtdDisponivel: 0,
      localShowModal: this.showModal,
    };
  },
  props: ["prods", "showModal"],
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
  },
  methods: {
    changeShow() {
      this.localShowModal = !this.localShowModal;
      this.$emit("update-dialog-cancel", this.localShowModal);
    },
    selecao(item, apre) {
      if (item.qtd == "+mais") {
        this.manual_show = true;
        return;
      }
      const limit = this.prods;
      let qtd = 0;
      limit.produtos.forEach((prod) => {
        qtd += prod.qtd;
      });
      limit.produtos.forEach((prod) => {
        prod.block_qtd = qtd;
      });
      this.$carrinho.AddPedido = [];
      this.$carrinho.AddPedido = limit;

      this.$carrinho.selecao(item, apre);
    },
    qtdSelect(item) {
      const quantidade = this.$carrinho.carrinho.reduce((acc, act) => {
        let qtd = 0;
        act.produtos.forEach((prod) => {
          if (!prod.dscproduto.toLowerCase().includes("vestiário")) {
            qtd += 1;
          } else {
            qtd -= 1;
          }
        });
        return acc + qtd;
      }, 0);
      let qtd = quantidade;
      this.qtdDisponivel = qtd;
      const res = [0];

      for (let x = 0; x < qtd; x++) {
        res.push(x + 1);
      }

      item ? res.push(item) : null;
      if (this.idtipo_pessoa) {
        res.push("+mais");
      }
      return res;
    },
  },
  mounted() {
    this.qtdSelect();
    if (this.qtdDisponivel <= 0) {
      this.changeShow;
    }
  },
};
</script>

<style scoped>
.modal-produtos-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: flex-start;
  align-items: center;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  margin-bottom: 15px;
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  padding: 8px;
}
.modal-produtos-container10 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.modal-produtos-text01 {
  color: black;
}

.prodhelp {
  background: antiquewhite !important;
  color: gray !important;
  padding: 2px 2px !important;
}

.modal-produtos-container11 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.modal-produtos-text02 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}
.modal-produtos-text03 {
  color: var(--dl-color-principal);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text04 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.modal-produtos-text03-1 {
  color: var(--dl-color-principal);
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.modal-produtos-text04-1 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.modal-produtos-container12 {
  flex: 0 0 auto;
  width: 50%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
</style>
